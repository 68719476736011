import React, { useState } from 'react';
import {
  Body,
  Button,
  Grid,
  Heading,
  Spinner
} from '@walmart-web/livingdesign-components';
import { useLocation } from 'react-router-dom';

import styles from './styles.module.css';
import configuration from '../../../../config/config';
import { logger } from '../../../../utils/logger';
import { useTranslation } from 'react-i18next';
import apiCall from '../../../../utils/api';
import ApiPath from '../../../../constants/api';
import API, { API_STATUS_CODE } from '../../../../constants/common';

type Props = {
  handleRegisterLaterClick: () => unknown;
};

const RegistrationConfirmation: React.FC<Props> = ({
  handleRegisterLaterClick
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const { surveyId, panelistHash } = location.state || {};
  const { t } = useTranslation('common');

  if (!panelistHash || !surveyId) return <></>;

  const handleNoClick = async () => {
    if (loading) return;
    logger.clickStream('waitlist_register_later_click', { panelistHash });

    try {
      setLoading(true);
      const response = await apiCall(
        ApiPath.recruit_waitlist(panelistHash),
        API.METHOD.POST
      );
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        logger.analytics('recruit_waitlist_email_success', {
          panelistHash,
          surveyId
        });
        handleRegisterLaterClick();
        return;
      }
      logger.analytics('recruit_waitlist_email_error', {
        panelistHash,
        surveyId,
        statusCode: response?.status,
        error: response?.error?.message || 'Unexpected error'
      });
    } catch (error) {
      logger.error('recruit_waitlist_email_error', {
        panelistHash,
        surveyId,
        error: error?.message
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterNowClick = () => {
    if (loading) return;
    logger.clickStream('waitlist_register_now_click', {
      panelist: panelistHash
    });
    window.location.href = `${configuration.uiDomain}/onboarding/survey/panelist/${surveyId}?panelist_hash=${panelistHash}`;
  };

  return (
    <>
      <img
        src="/assets/images/customer-spark-app-icon.svg"
        className={styles.sparkAppLogo}
        alt="Customer Spark App Logo"
        data-testid="customer-spark-app-logo"
      />

      <Grid UNSAFE_className={styles.messageBox}>
        <Heading size="medium" data-testid="waitlist-qualified-congrats">
          {t('congratulations')}
        </Heading>
        <Body
          size="medium"
          UNSAFE_className={styles.messageBody}
          data-testid="waitlist-qualified-message"
        >
          {t('qualifiedToBecomeMember', { ns: 'waitlist' })}
          <br />
          <br />
          {t('wouldYouContinueForRegistration', { ns: 'waitlist' })}
        </Body>

        <Grid UNSAFE_className={styles.buttonContainer}>
          <Button
            UNSAFE_className={styles.noBtn}
            variant="secondary"
            size="medium"
            onClick={handleNoClick}
            disabled={loading}
            data-testid="waitlist-register-later-btn"
          >
            {loading ? <Spinner size="small" /> : t('noMaybeLater')}
          </Button>
          <Button
            variant="primary"
            size="medium"
            onClick={handleRegisterNowClick}
            disabled={loading}
            data-testid="waitlist-register-now-btn"
          >
            {t('yesContinue')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default RegistrationConfirmation;
